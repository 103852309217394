import { ApplicationPermissions } from '@StellaModel/models/common/PermissionGroup';
import { lazy } from 'react';
import { Navigate, RouteObject, RouterProvider, createBrowserRouter, redirect } from 'react-router-dom';
import './App.css';
import AuthLayout from './layout/AuthLayout';
import PermissionRoute from './layout/PermissionRoute';
import './styles/daterangepicker.css';

const DetailsClaim = lazy(() => import('@pages/claims/details'));
const ClaimPage = lazy(() => import('@pages/claims/list'));
const ExcludedPage = lazy(() => import('@pages/excluded'));
const DetailsExcluded = lazy(() => import('@pages/excluded/details'));
const ReconcileIMailIssuePage = lazy(() => import('@pages/reconcile-iMail-issues'));
const TransferredCasesToDCBL = lazy(() => import('@pages/transferred-cases-to-DCBL'));
const Logout = lazy(() => import('@pages/logout'));

type RouteWithRole = RouteObject & { roles?: ApplicationPermissions[] };

const router = createBrowserRouter([
    {
        path: '/',
        element: <AuthLayout />,
        children: (
            [
                {
                    index: true,
                    element: <Navigate to={'claims'} />,
                },
                {
                    path: 'claims',
                    element: <ClaimPage />,
                    roles: [
                        ApplicationPermissions.LitigationClaimAccess,
                        ApplicationPermissions.LitigationClaimHandling,
                    ],
                },
                {
                    path: 'claims/:Id',
                    element: <DetailsClaim />,
                    roles: [
                        ApplicationPermissions.LitigationClaimAccess,
                        ApplicationPermissions.LitigationClaimHandling,
                    ],
                },
                {
                    path: 'excluded',
                    element: <ExcludedPage />,
                    roles: [
                        ApplicationPermissions.LitigationClaimAccess,
                        ApplicationPermissions.LitigationClaimHandling,
                    ],
                },
                {
                    path: 'excluded/:Id',
                    element: <DetailsExcluded />,
                    roles: [
                        ApplicationPermissions.LitigationClaimAccess,
                        ApplicationPermissions.LitigationClaimHandling,
                    ],
                },
                {
                    path: 'reconcile-iMail-issues',
                    element: <ReconcileIMailIssuePage />,
                    roles: [
                        ApplicationPermissions.LitigationClaimAccess,
                        ApplicationPermissions.LitigationClaimHandling,
                    ],
                },
                {
                    path: 'transferred-cases-to-DCBL',
                    element: <TransferredCasesToDCBL />,
                    roles: [
                        ApplicationPermissions.LitigationClaimAccess,
                        ApplicationPermissions.LitigationClaimHandling,
                    ],
                },
                {
                    path: 'logout',
                    element: <Logout />,
                },
                {
                    path: '*',
                    loader: () => redirect('/'),
                },
            ] as RouteWithRole[]
        ).map((route) => {
            if (route.roles) {
                const _route: RouteObject = {
                    ...route,
                    element: <PermissionRoute roles={route.roles}>{route.element}</PermissionRoute>,
                };
                return _route;
            }

            return route;
        }),
    },
]);

function App() {
    return <RouterProvider router={router} />;
}

export default App;
