import { Paging } from '@Core';
import { BaseHttpController } from '@Core/controller/BaseHttpController';
import { LitigableContravention } from '@LitigationModel/models/LitigableContravention';
import { VerificationWithDetail } from '@LitigationModel/models/res-model/VerificationWithDetail';
import { RealTimeReq } from '@LitigationModel/models/verification-req/RealTimeReq';
import { AxiosInstance } from 'axios';
import { IVerificationHttpController, IVerificationWithDetailProps } from '../IVerificationHttpController';
import { ReopenLitigableContraventionReq } from '@LitigationModel/models/req-model/ReopenLitigableContraventionReq';

export class VerificationHttpController
    extends BaseHttpController<LitigableContravention>
    implements IVerificationHttpController
{
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'verification', client);
    }

    async filter(props: IVerificationWithDetailProps): Promise<Paging<VerificationWithDetail>> {
        return await this.doPost({ path: 'filter', body: props }).then((res) => res.data);
    }

    async reopenLitigableContravention(props: ReopenLitigableContraventionReq): Promise<LitigableContravention> {
        return await this.doPost({ path: 'reopen-litigable-contravention', body: props }).then((res) => res.data);
    }

    async getById(id: number): Promise<VerificationWithDetail> {
        return await this.doGet({ path: `${id}` }).then((res) => res.data);
    }

    async upsertLitigablePCN(props: Partial<LitigableContravention>) {
        return await this.doPost({ path: 'upsert-litigable-pcn', body: props }).then((res) => res.data);
    }

    async excludeLitigableContravention(props: Partial<LitigableContravention>) {
        return await this.doPost({ path: 'exclude', body: props }).then((res) => res.data);
    }

    async checkingCaseAuditIsAvailable(props: VerificationWithDetail): Promise<boolean> {
        return await this.doPost({ path: 'checking-case-audit-available', body: props }).then((res) => res.data);
    }

    async caseAuditRealTime(props: RealTimeReq) {
        return await this.doPost({ path: 'case-audit-realtime', body: props }).then((res) => res.data);
    }

    async bulkUpsertLitigableContravention(litigableContraventions: LitigableContravention[]): Promise<any> {
        return await this.doPost({ path: 'bulk-upsert-litigable-pcn', body: litigableContraventions }).then(
            (res) => res.data
        );
    }
}
