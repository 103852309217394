import { UserStelHttpController } from '@StellaModel/controllers/http/UserStelHttpController';
import { appConfig } from '@configs/index';
import { axiosClient } from './axiosClient';
import { VerificationHttpController } from '@LitigationModel/controllers/http/VerificationHttpController';
import { ContraventionHttpController } from '@StellaModel/controllers/http/ContraventionHttpController';
import { EnumListHttpController } from '@LitigationModel/controllers/http/EnumListHttpController';
import { ClaimHttpController } from '@LitigationModel/controllers/http/ClaimHttpController';
import { ExcludedHttpController } from '@LitigationModel/controllers/http/ExcludedHttpController';
import { FleetHireHttpController } from '@LitigationModel/controllers/http/FleetHireHttpController';
import { DefendantHttpController } from '@LitigationModel/controllers/http/DefendantHttpController';
import { ClaimManualActionHttpController } from '@LitigationModel/controllers/http/ClaimManualActionHttpController';
import { CorrespondenceHttpController } from '@LitigationModel/controllers/http/CorrespondenceHttpController';
import { SecondPlacementHttpController } from '@LitigationModel/controllers/http/SecondPlacementHttpController';

const apiUrl = appConfig.gateway.backendURL;

export const userStellaController = new UserStelHttpController(apiUrl, axiosClient);
export const verificationController = new VerificationHttpController(apiUrl, axiosClient);
export const contraventionController = new ContraventionHttpController(apiUrl, axiosClient);
export const enumListHttpController = new EnumListHttpController(apiUrl, axiosClient);
export const claimHttpController = new ClaimHttpController(apiUrl, axiosClient);
export const fleetHireHttpController = new FleetHireHttpController(apiUrl, axiosClient);
export const exCludedController = new ExcludedHttpController(apiUrl, axiosClient);
export const defendantController = new DefendantHttpController(apiUrl, axiosClient);
export const claimManualActionController = new ClaimManualActionHttpController(apiUrl, axiosClient);
export const correspondenceHttpController = new CorrespondenceHttpController(apiUrl, axiosClient);
export const secondPlacementHttpController = new SecondPlacementHttpController(apiUrl, axiosClient);
