import { Paging } from '@Core';
import { BaseHttpController } from '@Core/controller/BaseHttpController';
import { LitigableContravention } from '@LitigationModel/models/LitigableContravention';
import { AxiosInstance } from 'axios';
import { IGetSecondPlacementProps, ISecondPlacementHttpController } from '../ISecondPlacementHttpController';

export class SecondPlacementHttpController
    extends BaseHttpController<LitigableContravention>
    implements ISecondPlacementHttpController
{
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'second-placement', client);
    }

    async filterSecondPlacement(props: IGetSecondPlacementProps): Promise<Paging<LitigableContravention>> {
        return await this.doPost({ path: 'filter', body: props }).then((res) => res.data);
    }

    async exportSecondPlacement(props: IGetSecondPlacementProps): Promise<{ buffer: Buffer }> {
        return await this.doPost({ path: 'export', body: props }).then((res) => res.data);
    }
}
