import { ApplicationPermissions } from '@StellaModel/models/common/PermissionGroup';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '@configs/authConfig';
import { appConfig } from '@configs/index';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {
    Button,
    Container,
    Dropdown,
    Form,
    FormControl,
    Image,
    InputGroup,
    Nav,
    NavDropdown,
    Navbar,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import MyProfilePicture from 'src/assets/images/MyProfilePicture.jpeg';
import { useAuth } from 'src/providers/AuthProvider';

type Props = {};

const AppHeader = (props: Props) => {
    const { instance, inProgress, accounts, logger } = useMsal();
    const currentUser = accounts[0];

    const [avatar, setAvatar] = useState(MyProfilePicture);

    const auth = useAuth();

    useEffect(() => {
        if (accounts.length) {
            getUserAvatar();
        }
    }, []);

    const getUserAvatar = async () => {
        instance
            .acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
            })
            .then((res) => {
                const apiURL = 'https://graph.microsoft.com/v1.0/me/photo/$value';
                axios(apiURL, {
                    headers: { Authorization: `Bearer ${res.accessToken}` },
                    responseType: 'arraybuffer',
                })
                    .then((response) => {
                        const reader = new FileReader();
                        reader.onloadend = () => {
                            const base64data = reader.result as string;
                            setAvatar(base64data);
                        };
                        const blob = new Blob([response.data], { type: 'image/jpeg' });
                        reader.readAsDataURL(blob);
                    })
                    .catch((err) => {
                        // setAvatar('');
                    });
            });
    };

    const stellaUrl = appConfig.application.stella;
    const navbar = [
        {
            title: 'Site Management',
            id: 'SiteManagementNavDropdown',
            url: `${stellaUrl}/SiteManagement`,
            access: [ApplicationPermissions.SiteManagementAccess],
            items: [
                { title: 'Companies', href: '/Company/List?tenantId=1' },
                { title: 'Portfolios', href: '/Portfolio/List' },
                { title: 'Tag Search', href: '/Tag/Search' },
                { title: 'Users', href: '/User/List' },
                { title: 'Country Regions', href: '/CountryRegion/List' },
                { title: 'Vehicle Lists', href: '/VehicleListController/List' },
            ],
        },
        {
            title: 'Contraventions',
            id: 'ContraventionsNavDropdown',
            url: `${stellaUrl}/ContraventionManagement`,
            access: [ApplicationPermissions.ContraventionAccess],
            items: [
                { title: 'Contravention Search', href: '/Contravention/Search' },
                { title: 'Appeal Search', href: '/Appeal/Search' },
                { title: 'Tag Search', href: '/Tag/Search' },
                { divider: true },
                { title: 'Process Scanned Appeals', href: '/ScannedAppeal/Summary' },
                { title: 'Pending Appeal Work Queues', href: '/PendingAppealWorkQueue/List' },
                { title: 'Verify AI Appeals', href: '/VerifyAIAppeal/List' },
                { title: 'No Decision AI Appeals', href: '/NoDecisionAIAppeal/List' },
                { title: 'Pending Warden Verifications', href: '/PendingWardenVerification/List' },
                { title: 'Reconcile Account Correspondences', href: '/AccountCorrespondence/Reconcile' },
                { divider: true },
                { title: 'Correspondence Templates', href: '/CorrespondenceTemplate/List' },
                { title: 'Workflow Mappings', href: '/WorkflowMapping/List' },
                { title: 'Workflow Letter Template Mappings', href: '/WorkflowLetterTemplateMapping/List' },
            ],
        },
        {
            title: 'Litigation',
            id: 'DataQualityNavDropdown',
            url: ``,
            items: [
                // {
                //     title: 'Case audits',
                //     href: '/case-audits',
                //     access: [
                //         ApplicationPermissions.LitigationVerifyAccess,
                //         ApplicationPermissions.LitigationVerifyHandling,
                //     ],
                // },
                {
                    title: 'Claims',
                    href: '/claims',
                    access: [
                        ApplicationPermissions.LitigationClaimAccess,
                        ApplicationPermissions.LitigationClaimHandling,
                    ],
                },
                {
                    title: 'Excluded',
                    href: '/excluded',
                    access: [
                        ApplicationPermissions.LitigationClaimAccess,
                        ApplicationPermissions.LitigationClaimHandling,
                    ],
                },
                {
                    title: 'Reconcile iMail Issues',
                    href: '/reconcile-iMail-issues',
                    access: [
                        ApplicationPermissions.LitigationClaimAccess,
                        ApplicationPermissions.LitigationClaimHandling,
                    ],
                },
                // {
                //     title: 'Fleet hire case',
                //     href: '/fleet-hire',
                //     access: [
                //         ApplicationPermissions.LitigationClaimAccess,
                //         ApplicationPermissions.LitigationClaimHandling,
                //     ],
                // },
                {
                    title: 'Transferred cases to DCBL',
                    href: '/transferred-cases-to-DCBL',
                    access: [
                        ApplicationPermissions.LitigationClaimAccess,
                        ApplicationPermissions.LitigationClaimHandling,
                    ],
                },
            ],
        },
        {
            title: 'Data Quality',
            id: 'DataQualityNavDropdown',
            url: `${stellaUrl}`,
            access: [ApplicationPermissions.DataQualityTriage],
            items: [{ title: 'Reported Issues', href: '/DataIssue/List' }],
        },
        {
            title: 'Admin',
            id: 'AdminNavDropdown',
            url: `${stellaUrl}`,
            access: [ApplicationPermissions.SystemConfiguration],
            items: [
                { title: 'Users', href: '/User/List' },
                { title: 'Permission Groups', href: '/PermissionGroup/List' },
            ],
        },
    ];
    return (
        <header>
            <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
                <Container fluid>
                    <Navbar.Brand href={stellaUrl}>Stella</Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            {navbar.map((nav, index) => {
                                if (nav.access && !nav.access?.some((a) => auth.user?.permissions.includes(a))) {
                                    return null;
                                }

                                return (
                                    <NavDropdown key={nav.id + index} title={nav.title + ' '} id={nav.id}>
                                        {nav.items.map((navItem, index) => {
                                            const notPermit =
                                                navItem.access &&
                                                !navItem.access.some((a) => auth.user?.permissions.includes(a));

                                            if (!auth.isAdmin && notPermit) return null;

                                            if (navItem.divider) {
                                                return <Dropdown.Divider key={index} />;
                                            }
                                            return (
                                                <NavDropdown.Item
                                                    key={`${navItem.title}${index}`}
                                                    as={Link}
                                                    to={nav.url + navItem.href}
                                                >
                                                    {navItem.title}
                                                </NavDropdown.Item>
                                            );
                                        })}
                                    </NavDropdown>
                                );
                            })}
                        </Nav>

                        <Form className="d-flex" action={appConfig.application.stella} method="get">
                            <Form.Control
                                type="search"
                                placeholder="Search"
                                aria-label="Search"
                                id="GlobalSearchInput"
                                name="Term"
                                autoComplete="off"
                                className="me-2"
                            />
                            <Button variant="outline-success" type="submit">
                                Search
                            </Button>
                        </Form>
                        <div className="d-flex menu-profile ms-2">
                            {currentUser.name || currentUser.username}
                            <Image src={avatar} width={30} height={30} roundedCircle className="ms-2" />
                            {/* <img src="@Url.Action("MyProfilePicture", "Account", new { area = string.Empty })"
                id="MenuCurrentUserProfilePicture" className="menu-profile-picture"
                data-current-user-id="@User.GetUserId()" /> */}
                        </div>
                        <div className="ms-2" style={{ minHeight: 46.59 }}>
                            <button
                                className="btn btn-light"
                                data-bs-toggle="tooltip"
                                title=""
                                data-bs-placement="left"
                                data-toggle="dynamic-modal"
                                data-url="/DataIssue/Report?url=https%3A%2F%2Fstella-dev-ukpc.azurewebsites.net%2FDataIssue%2FList"
                                data-bs-original-title="Report Data Issue"
                            >
                                <i className="bi bi-emoji-frown"></i>
                            </button>
                        </div>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    );
};

export default AppHeader;
