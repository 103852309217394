import { BaseHttpController } from '@Core/controller/BaseHttpController';
import { Contravention } from '@StellaModel/models/Contravention';
import { AxiosInstance } from 'axios';
import { IContraventionHttpController } from '../IContraventionHttpController';

export class ContraventionHttpController
    extends BaseHttpController<Contravention>
    implements IContraventionHttpController
{
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'Contravention', client);
    }

    async getById(id: number): Promise<Contravention> {
        return await this.doGet({ path: `${id}` }).then((res) => res.data);
    }
}
