import GetBooleanFromEnv from 'src/helpers';

export const appConfig = {
    application: {
        stella: process.env.REACT_APP_STELLA_URL!,
        templateIdIMailIndividual: process.env.REACT_APP_TEMPLATE_ID_IMAIL_INDIVIDUAL!,
        templateIdIMailCompany: process.env.REACT_APP_TEMPLATE_ID_IMAIL_COMPANY!,
        templateIdIMailReminder: process.env.REACT_APP_TEMPLATE_ID_IMAIL_REMINDER!,
        regionCode: process.env.REACT_APP_REGION_CODE,
    },
    gateway: {
        backendURL: process.env.REACT_APP_BACKEND_URL!,
        blobURL: process.env.REACT_APP_BLOB_URL!,
    },

    flagFeatures: {
        multiLanguage: GetBooleanFromEnv(process.env.REACT_APP_MULTI_LANGUAGE!),
    },
};
