import { InteractionRequiredAuthError, InteractionStatus, SilentRequest } from '@azure/msal-browser';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import LoadingScreen from '@components/LoadingScreen';
import { loginRequest } from '@configs/authConfig';
import { axiosClient } from '@controllers/axiosClient';
import Login from '@pages/login';
import { Suspense, useLayoutEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import { AuthProvider } from 'src/providers/AuthProvider';
import AppHeader from './AppHeader';

function ProtectedChild() {
    const { instance, inProgress, accounts, logger } = useMsal();
    const [hasToken, setHasToken] = useState(false);

    useLayoutEffect(() => {
        if (inProgress !== InteractionStatus.None) return;
        const accessTokenRequest: SilentRequest = {
            ...loginRequest,
            account: instance.getActiveAccount()!,
        };
        const getToken = async () => {
            const accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest);
            return accessTokenResponse;
        };

        axiosClient.interceptors.request.use(
            async (config) => {
                try {
                    const token = await getToken();
                    config.headers.Authorization = 'Bearer ' + token.idToken;
                } catch (error) {
                    if (error instanceof InteractionRequiredAuthError) {
                        instance.acquireTokenRedirect(accessTokenRequest);
                    }
                    console.error(error);
                }
                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );

        axiosClient.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error?.response?.status === 401) {
                    console.error('_error', error.response);
                    instance.acquireTokenRedirect(loginRequest);
                } else {
                    console.error('_error', error.response);
                    // pushError(
                    //     typeof error?.response?.data === 'string'
                    //         ? error?.response?.data
                    //         : error?.response?.data?.message || error.message || 'Server error!'
                    // );
                    return Promise.reject(error);
                }
            }
        );

        setHasToken(true);
    }, [inProgress, instance]);

    if (!hasToken || inProgress !== InteractionStatus.None) {
        return (
            <div className="d-flex justify-content-center align-items-center min-vh-100">
                <LoadingScreen isLoading={true} />
            </div>
        );
    }

    if (window.location.pathname.includes('logout')) {
        return (
            <Suspense>
                <Outlet />
            </Suspense>
        );
    }

    return (
        <AuthProvider>
            <AppHeader />

            <Container fluid={true}>
                <main className="pb-3 h-100 px-4">
                    <Suspense>
                        <Outlet />
                    </Suspense>
                </main>
            </Container>

            <footer className="border-top footer text-muted">
                <div className="container-fluid">
                    &copy; {new Date().getFullYear()} - UK Parking Control Ltd
                    <div className="float-end" id="BootstrapBreakpointLabel">
                        <div className="d-sm-none">XS</div>
                        <div className="d-none d-sm-inline-block d-md-none">SM</div>
                        <div className="d-none d-md-inline-block d-lg-none">MD</div>
                        <div className="d-none d-lg-inline-block d-xl-none">LG</div>
                        <div className="d-none d-xl-inline-block d-xxl-none">XL</div>
                        <div className="d-none d-xxl-inline-block">XXL</div>
                    </div>
                </div>
            </footer>
        </AuthProvider>
    );
}

function AuthLayout() {
    return (
        <>
            <AuthenticatedTemplate>
                <ProtectedChild />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Login />
            </UnauthenticatedTemplate>
        </>
    );
}

export default AuthLayout;
