import { BaseHttpController } from '@Core/controller/BaseHttpController';
import { Claim } from '@LitigationModel/models/Claim';
import { Defendant } from '@LitigationModel/models/Defendant';
import { UpdateDefendantReq } from '@LitigationModel/models/claim-action/UpdateDefendantReq';
import { AxiosInstance } from 'axios';
import { IDefendantHttpController } from '../IDefendantHttpController';

export class DefendantHttpController extends BaseHttpController<Defendant> implements IDefendantHttpController {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'defendant', client);
    }

    async updateInformation(props: UpdateDefendantReq): Promise<Claim> {
        return await this.doPost({ path: 'upsert', body: props }).then((res) => res.data);
    }
}
