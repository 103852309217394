import { useMsal } from '@azure/msal-react';
import { loginRequest } from '@configs/authConfig';
import { useEffect } from 'react';

export default function Login() {
    const { instance } = useMsal();

    useEffect(() => {
        instance.loginRedirect(loginRequest).catch((e) => {
            console.error(e);
        });
    }, []);

    // const handleLogin = () => {
    //     instance.loginPopup(loginRequest).then((res) => {
    //         console.log(res);
    //     });
    // };
    return <div></div>;
}
