import { Spinner } from 'react-bootstrap';

type Props = {
    isLoading?: boolean;
};

const LoadingScreen = (props: Props) => {
    return (
        <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
        </Spinner>
    );
};

export default LoadingScreen;
