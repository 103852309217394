import { Paging } from '@Core';
import { Claim } from '@LitigationModel/models/Claim';
import { appConfig } from '@configs/index';
import moment from 'moment';
// import 'moment-timezone';

type OnChangeCallback = (value: string) => void;

export default function GetBooleanFromEnv(string: string) {
    return string === 'true';
}

export const formatDate = (date?: Date) => {
    return moment(date).format('DD/MM/yyyy HH:mm:ss');
};

export const formatDateWithoutSeconds = (date?: Date) => {
    return moment(date).local().format('DD/MM/yyyy HH:mm');
};
export const formatDateDay = (date?: Date) => {
    return moment(date).format('DD/MM/yyyy');
};

export const formatCurrencyUK = (amount?: number) => {
    if (!amount) return '£0.00';
    return amount.toLocaleString('en-GB', {
        style: 'currency',
        currency: 'GBP',
    });
};

export const convertSecondsToHoursAndMinutes = (value: number) => {
    const hours = Math.floor(value / 3600);
    const minutes = Math.floor((value % 3600) / 60);
    const remainingSeconds = value % 60;
    return `${hours} hrs ${minutes} mins ${remainingSeconds} secs`;
};

export const convertMinutes = (value: number) => {
    const minutes = Math.floor((value % 3600) / 60);
    return `${minutes} mins`;
};
export const getLinkContraventionDetailFrStella = (contraventionId: number): string => {
    return `${appConfig.application.stella}/ContraventionManagement/Contravention/Details/${contraventionId}`;
};

export const groupsAddress = (address: string[]) => address.filter((item) => item).join(', ');

export const groupsAddressMultiple = (address: string[]) => address.filter((item) => item).join('\n');

export function defaultPaging<T>(params?: Partial<Paging<T>>): Paging<T> {
    return {
        page: 1,
        pageSize: 10,
        rows: [],
        total: 0,
        totalPages: 1,
        ...params,
    };
}

export const trimLeadingSpaces = (value: string, onChangeCallback: OnChangeCallback): void => {
    let startIndex = 0;
    for (let i = 0; i < value.length; i++) {
        if (value[i] !== ' ') {
            startIndex = i;
            break;
        }
    }
    const trimmedValue = value.substring(startIndex);
    onChangeCallback(trimmedValue);
};
