import { ApplicationPermissions } from '@StellaModel/models/common/PermissionGroup';
import { InteractionStatus } from '@azure/msal-browser';
import LoadingScreen from '@components/LoadingScreen';
import { userStellaController } from '@controllers/index';
import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation, useOutletContext } from 'react-router-dom';

interface User {
    name: string;
    permissions: ApplicationPermissions[];
}

interface Props {}

export const useAuthContext = (props?: Props) => {
    const [user, setUser] = useState<User>();

    useEffect(() => {
        userStellaController.getPermission().then((p) => {
            setUser({
                name: '',
                permissions: p,
            });
        });
    }, []);

    const isAdmin = !!user?.permissions.includes(ApplicationPermissions.LitigationAdmin);

    return {
        user,
        isAdmin,
    };
};
export type AuthContextType = ReturnType<typeof useAuthContext>;
export const AuthContext = createContext<AuthContextType>({} as any);

export const AuthProvider = ({ children }) => {
    const authCtx = useAuthContext();

    if (!authCtx.user) {
        return (
            <div className="d-flex justify-content-center align-items-center min-vh-100">
                <LoadingScreen isLoading={true} />
            </div>
        );
    }

    return <AuthContext.Provider value={authCtx}>{children}</AuthContext.Provider>;
};

export function useAuth() {
    return useContext(AuthContext);
}
