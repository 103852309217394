import { Paging } from '@Core';
import { BaseHttpController } from '@Core/controller/BaseHttpController';
import { CourtClaim } from '@LitigationModel/models/CourtClaim';
import { Defendant } from '@LitigationModel/models/Defendant';
import { DefendantCorrespondence } from '@LitigationModel/models/DefendantCorrespondence';
import { IssueCorrespondenceRequest } from '@LitigationModel/models/IssueCorrespondence';
import { Tag } from '@LitigationModel/models/Tag';
import { TakePayment } from '@LitigationModel/models/TakePayment';
import { AcceptAdmissionReq } from '@LitigationModel/models/claim-action/AcceptAdmissionReq';
import { BaseClaimAction } from '@LitigationModel/models/claim-action/BaseClaimAction';
import { BulkReAssignReq } from '@LitigationModel/models/claim-action/BulkReAssignReq';
import { ChangeAdditionalChargeReq } from '@LitigationModel/models/claim-action/ChangeAdditionalChargeReq';
import { ChangeHandlerReq } from '@LitigationModel/models/claim-action/ChangeHandlerReq';
import { DiscontinueReq } from '@LitigationModel/models/claim-action/DiscontinueReq';
import { DiscountActionReq } from '@LitigationModel/models/claim-action/DiscountActionReq';
import { EventLogReq } from '@LitigationModel/models/claim-action/EventLogReq';
import { ExcludeClaimReq } from '@LitigationModel/models/claim-action/ExcludeClaimReq';
import { ManualPaymentReq } from '@LitigationModel/models/claim-action/ManualPaymentReq';
import { PutOnHold } from '@LitigationModel/models/claim-action/PutOnHold';
import { RevertAdditionalChargeReq } from '@LitigationModel/models/claim-action/RevertAdditionalChargeReq';
import { RevertPaymentTransactionReq } from '@LitigationModel/models/claim-action/RevertPaymentTransactionReq';
import { SettleReq } from '@LitigationModel/models/claim-action/SettleReq';
import { TakeOffHold } from '@LitigationModel/models/claim-action/TakeOffHold';
import { TransferHandlerReq } from '@LitigationModel/models/claim-action/TransferHandlerReq';
import { SetJudgementAsideReq } from '@LitigationModel/models/claim-manual-action/SetJudgementAsideReq';
import { CreditDetail } from '@LitigationModel/models/res-model/VerificationWithDetail';
import { AxiosInstance } from 'axios';
import { Claim } from '../../models/Claim';
import { ClaimStatusUpdate, IClaimHttpController, IGetClaimProps } from '../IClaimHttpController';

export class ClaimHttpController extends BaseHttpController<Claim> implements IClaimHttpController {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'claim', client);
    }

    async updateClaimStatus(props: ClaimStatusUpdate): Promise<Claim> {
        return await this.doPost({ path: 'update-status', body: props }).then((res) => res.data);
    }

    async getClaimDetail(id: number): Promise<Claim> {
        return await this.doGet({ path: `${id}` }).then((res) => res.data);
    }

    async createClaimTag(props: Tag): Promise<Tag[]> {
        return await this.doPost({ path: 'create-tag', body: props }).then((res) => res.data);
    }

    async filterClaims(props: IGetClaimProps): Promise<Paging<Claim>> {
        return await this.doPost({ path: 'filter', body: props }).then((res) => res.data);
    }

    async upsertClaim(props: Claim): Promise<Claim> {
        return await this.doPost({ path: 'claim-upsert', body: props }).then((res) => res.data);
    }

    async getCCjs(claimId: number): Promise<CreditDetail> {
        return await this.doGet({ path: `get-ccjs/${claimId}` }).then((res) => res.data);
    }

    async removeTag(id: number) {
        return await this.doGet({ path: `remove-tag/${id}` }).then((res) => res.data);
    }

    async putOnHold(putOnHold: PutOnHold): Promise<Claim> {
        return await this.doPost({ path: 'put-on-hold', body: putOnHold }).then((res) => res.data);
    }

    async takeOffHold(takeOffHold: TakeOffHold): Promise<Claim> {
        return await this.doPost({ path: 'take-off-hold', body: takeOffHold }).then((res) => res.data);
    }

    async changeHandler(props: ChangeHandlerReq) {
        return await this.doPost({ path: 'change-handler', body: props }).then((res) => res.data);
    }

    async previewFile(blobName: string) {
        return await this.doGet({ path: `preview-file/1?blobName=${blobName}` }).then((res) => res.data);
    }

    async uploadFile(props: any): Promise<Claim> {
        return await this.doPost({ path: `upload-file`, body: props }).then((res) => res.data);
    }

    async discontinue(props: DiscontinueReq): Promise<Claim> {
        return await this.doPost({ path: 'discontinue', body: props }).then((res) => res.data);
    }

    async settle(props: SettleReq): Promise<Claim> {
        return await this.doPost({ path: 'settle', body: props }).then((res) => res.data);
    }

    async changeAdditionalCharge(props: ChangeAdditionalChargeReq) {
        return await this.doPost({ path: 'change-additional-charge', body: props }).then((res) => res.data);
    }

    async issueClaim(props: CourtClaim): Promise<Claim> {
        return await this.doPost({ path: 'issue-claim', body: props }).then((res) => res.data);
    }

    async issueCorrespondence(props: IssueCorrespondenceRequest[]): Promise<Claim> {
        return await this.doPost({ path: 'issue-correspondence', body: props }).then((res) => res.data);
    }
    async manualPayment(props: ManualPaymentReq): Promise<Claim> {
        return await this.doPost({ path: 'manual-payment', body: props }).then((res) => res.data);
    }
    async addDiscount(props: DiscountActionReq): Promise<Claim> {
        return await this.doPost({ path: 'add-discount', body: props }).then((res) => res.data);
    }

    async addEventLog(props: EventLogReq): Promise<Claim> {
        return await this.doPost({ path: 'add-event-log', body: props }).then((res) => res.data);
    }

    async userViewClaim(props: BaseClaimAction): Promise<Claim> {
        return await this.doPost({ path: 'view-claim', body: props }).then((res) => res.data);
    }

    async updateAddress(props: Partial<Defendant>): Promise<Defendant> {
        return await this.doPost({ path: 'update-address', body: props }).then((res) => res.data);
    }

    async paidClaim(props: BaseClaimAction): Promise<Claim> {
        return await this.doPost({ path: 'paid-claim', body: props }).then((res) => res.data);
    }

    async bulkReAssignHandler(props: BulkReAssignReq) {
        return await this.doPost({ path: 'bulk-re-assign-handler', body: props }).then((res) => res.data);
    }

    async requestJudgmentDefault(props: BaseClaimAction): Promise<Claim> {
        return await this.doPost({ path: 'request-judgment-default', body: props }).then((res) => res.data);
    }

    async rejectPartAdmission(props: any): Promise<Claim> {
        return await this.doPost({ path: 'reject-part-admission', body: props }).then((res) => res.data);
    }

    async acceptAdmission(props: AcceptAdmissionReq): Promise<Claim> {
        return await this.doPost({ path: 'accept-admission', body: props }).then((res) => res.data);
    }

    async reverseAdditionalCharge(props: RevertAdditionalChargeReq): Promise<Claim> {
        return await this.doPost({ path: 'reverse-additional-charge', body: props }).then((res) => res.data);
    }

    async excludedClaim(props: ExcludeClaimReq): Promise<Claim> {
        return await this.doPost({ path: 'exclude-claim', body: props }).then((res) => res.data);
    }

    async revertDiscount(props: RevertPaymentTransactionReq): Promise<Claim> {
        return await this.doPost({ path: 'revert-discount', body: props }).then((res) => res.data);
    }

    async getClaimDetailByRef(ref: string): Promise<Claim> {
        return await this.doGet({ path: `by-ref/${ref}` }).then((res) => res.data);
    }

    async transferHandler(props: TransferHandlerReq): Promise<Claim> {
        return await this.doPost({ path: 'transfer-handler', body: props }).then((res) => res.data);
    }

    async setJudgementAside(props: SetJudgementAsideReq): Promise<Claim> {
        return await this.doPost({ path: 'set-judgement-aside', body: props }).then((res) => res.data);
    }

    async deleteCorrespondence(id: number): Promise<Claim> {
        return await this.doGet({ path: `correspondence/delete/${id}` }).then((res) => res.data);
    }

    rejectADefence() {
        throw new Error('Method not implemented.');
    }

    async takePayment(props: TakePayment): Promise<Claim> {
        return await this.doPost({ path: 'take-payment', body: props }).then((res) => res.data);
    }

    async getPayChargeUrl(claimId: number) {
        return await this.doGet({ path: `get-pay-charge-url/${claimId}` }).then((res) => res.data);
    }

    async initiateEnforcementProcess(props: BaseClaimAction): Promise<Claim> {
        return await this.doPost({ path: 'initiate-enforcement-process', body: props }).then((res) => res.data);
    }

    async downloadClaimsWithFilter(props: IGetClaimProps): Promise<{ buffer: Buffer }> {
        return await this.doPost({ path: 'download-claims', body: props }).then((res) => res.data);
    }
}

export interface ClaimReq {
    claim: Claim;
    formData: FormData;
}

//upsert Claim
// FormData

// form Append
// Loc?: Multer.File;
// ReplyForm?: Multer.File;
// ReminderLetter?: Multer.File;
// claim:Claim
