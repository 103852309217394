import { BaseHttpController } from '@Core/controller/BaseHttpController';
import { ApplicationPermissions } from '@StellaModel/models/common/PermissionGroup';
import { User } from '@StellaModel/models/common/User';
import { AxiosInstance } from 'axios';
import { IUserStelHttpController } from '../IUserStelHttpController';

export class UserStelHttpController extends BaseHttpController<User> implements IUserStelHttpController {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'User', client);
    }

    async getPermission(): Promise<ApplicationPermissions[]> {
        const res = await this.client.get(`${this.serviceURL}/${this.basePath}/get-permission`);
        return res.data;
    }
}
