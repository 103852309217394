import { Paging } from '@Core';
import { BaseHttpController } from '@Core/controller/BaseHttpController';
import {
    DefendantCorrespondence,
    DefendantCorrespondenceWithDefendant,
} from '@LitigationModel/models/DefendantCorrespondence';
import { CorrespondencePagingRes } from '@LitigationModel/models/res-model/CorrespondencePagingRes';
import { AxiosInstance } from 'axios';
import { ICorrespondenceHttpController, IGetCorrespondenceProps } from '../ICorrespondenceHttpController';

export class CorrespondenceHttpController
    extends BaseHttpController<DefendantCorrespondence>
    implements ICorrespondenceHttpController
{
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'correspondence', client);
    }

    async getListDate(props: IGetCorrespondenceProps): Promise<Paging<CorrespondencePagingRes>> {
        return await this.doPost({ path: 'get-list-date', body: props }).then((res) => res.data);
    }

    async getCorrespondenceByDate(
        props: IGetCorrespondenceProps
    ): Promise<Paging<DefendantCorrespondenceWithDefendant>> {
        return await this.doPost({ path: 'get-correspondence-by-date', body: props }).then((res) => res.data);
    }

    async manuallyResolve(props: IGetCorrespondenceProps): Promise<Paging<DefendantCorrespondenceWithDefendant>> {
        return await this.doPost({ path: 'manually-resolve', body: props }).then((res) => res.data);
    }
}
