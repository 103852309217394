import { AuthenticationResult, EventMessage, EventType, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import MultiProvider from '@components/MultiProvider';
import { msalConfig } from '@configs/authConfig';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import reportWebVitals from './reportWebVitals';
import i18n from './translations/config';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'src/styles/site.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import momentTz from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import './App.scss';
import App from './App';
momentTz.tz.setDefault('Europe/London');

export const msalInstance = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
    }
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const queryClient = new QueryClient();

root.render(
    <MultiProvider
        providers={[
            <QueryClientProvider client={queryClient} />,
            <MsalProvider instance={msalInstance} />,
            <I18nextProvider i18n={i18n} />,
            //
        ]}
    >
        <App />
    </MultiProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
