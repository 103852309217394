import { BaseHttpController } from '@Core/controller/BaseHttpController';
import { CourtClaim } from '@LitigationModel/models/CourtClaim';
import { AcceptAdmissionReq } from '@LitigationModel/models/claim-action/AcceptAdmissionReq';
import { BaseClaimAction } from '@LitigationModel/models/claim-action/BaseClaimAction';
import { DiscontinueReq } from '@LitigationModel/models/claim-action/DiscontinueReq';
import { PaidReq } from '@LitigationModel/models/claim-action/PaidReq';
import { SettleReq } from '@LitigationModel/models/claim-action/SettleReq';
import { AxiosInstance } from 'axios';
import { Claim } from '../../models/Claim';
import { IClaimManualActionHttpController } from '../IClaimManualActionHttpController';

export class ClaimManualActionHttpController
    extends BaseHttpController<Claim>
    implements IClaimManualActionHttpController
{
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'claim-manual-action', client);
    }

    async discontinueManual(props: DiscontinueReq): Promise<Claim> {
        return await this.doPost({ path: 'discontinue', body: props }).then((res) => res.data);
    }

    async issueClaimManual(props: CourtClaim): Promise<Claim> {
        return await this.doPost({ path: 'issue-claim', body: props }).then((res) => res.data);
    }

    async settleClaimManual(props: SettleReq): Promise<Claim> {
        return await this.doPost({ path: 'settle-claim', body: props }).then((res) => res.data);
    }

    async requestDefaultJudgmentManual(props: BaseClaimAction): Promise<Claim> {
        return await this.doPost({ path: 'default-judgment', body: props }).then((res) => res.data);
    }

    async requestJudgmentByAdmissionManual(props: AcceptAdmissionReq): Promise<Claim> {
        return await this.doPost({ path: 'request-judgment-by-admission', body: props }).then((res) => res.data);
    }

    async requestPaidClaimManual(props: PaidReq): Promise<Claim> {
        return await this.doPost({ path: 'paid-claim', body: props }).then((res) => res.data);
    }
}
