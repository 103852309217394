import { Paging } from '@Core';
import { BaseHttpController } from '@Core/controller/BaseHttpController';
import { AxiosInstance } from 'axios';
import { LitigableContravention } from '@LitigationModel/models/LitigableContravention';
import { IExcludedPCNHttpController, IExcludedProps } from '../IExcludedPCNHttpController';

export class ExcludedHttpController
    extends BaseHttpController<LitigableContravention>
    implements IExcludedPCNHttpController
{
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'excluded', client);
    }

    async filter(props: IExcludedProps): Promise<Paging<LitigableContravention>> {
        return await this.doPost({ path: 'filter', body: props }).then((res) => res.data);
    }

    async getById(id: number): Promise<LitigableContravention> {
        return await this.doGet({ path: `${id}` }).then((res) => res.data);
    }
}
