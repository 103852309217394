import { BaseHttpController } from '@Core/controller/BaseHttpController';
import { ExcludeReason } from '@LitigationModel/models/ExcludeReasons';
import { User } from '@StellaModel/models/common/User';
import { AxiosInstance } from 'axios';
import { IEnumListHttpController } from '../IEnumListHttpController';

export class EnumListHttpController extends BaseHttpController<any> implements IEnumListHttpController {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'enum', client);
    }

    async getExcludeReasons(): Promise<ExcludeReason[]> {
        return await this.doGet({ path: `get-exclude-reasons` }).then((res) => res.data);
    }

    async getHandler(): Promise<User[]> {
        return await this.client.get(`user/get-handlers`).then((res) => res.data);
    }

    async getVerifiers(): Promise<User[]> {
        return await this.client.get(`user/get-verifiers`).then((res) => res.data);
    }
}
