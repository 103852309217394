import { BaseModel } from '@Core';

export interface PermissionGroup extends BaseModel {
    Name: string;
    Description?: string;
    Permissions: number;
}

export enum ApplicationPermissions {
    SiteManagementAccess = 1,
    SiteManagementHandling = 2,
    ContraventionAccess = 4,
    ContraventionHandling = 8,
    ContraventionFinancial = 16,
    ContraventionConfiguration = 32,
    DataQualityTriage = 64,
    SystemConfiguration = 128,
    SystemDeveloper = 256,
    LocationViewSensitiveDocuments = 512,
    LocationContraventionConfiguration = 1024,
    VehicleListAccess = 2048,
    VehicleListHandling = 4096,
    VehicleListUse = 8192,
    // LitigationVerifyAccess = 16384,
    // LitigationVerifyHandling = 32768,
    LitigationClaimAccess = 65536,
    LitigationClaimHandling = 131072,
    LitigationAdmin = 262144,
    LitigationClaimReAssign = 524288,
}
