import { Paging } from '@Core';
import { BaseHttpController } from '@Core/controller/BaseHttpController';
import { ClaimPagingRes } from '@LitigationModel/models/res-model/ClaimPagingRes';
import { AxiosInstance } from 'axios';
import { Claim } from '../../models/Claim';
import { IGetClaimProps } from '../IClaimHttpController';
import { IFleetHireHttpController } from '../IFleetHireHttpController';

export class FleetHireHttpController extends BaseHttpController<Claim> implements IFleetHireHttpController {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'fleet-hire', client);
    }

    async filterFleetHire(props: IGetClaimProps): Promise<Paging<ClaimPagingRes>> {
        return await this.doPost({ path: 'filter', body: props }).then((res) => res.data);
    }
}
