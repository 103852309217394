import React from 'react';
import { Link } from 'react-router-dom';

type Props = {};

const AccessDenied = (props: Props) => {
    return (
        <div>
            <h1 className="text-danger">Access Denied</h1>
            <p>
                You do not have permission to view this page. If you believe this to be incorrect, please raise a ticket
                on the{' '}
                <Link to="https://servicedesk.ukpcextra.co.uk/" target="_blank">
                    I.T. Service Desk.
                </Link>
            </p>
        </div>
    );
};

export default AccessDenied;
