import { ApplicationPermissions } from '@StellaModel/models/common/PermissionGroup';
import { ReactNode } from 'react';
import { useAuth } from 'src/providers/AuthProvider';
import AccessDenied from './AccessDenied';

type Props = {
    roles: ApplicationPermissions[];
    children: ReactNode;
};

const PermissionRoute = (props: Props) => {
    const auth = useAuth();
    const hasLitigationAdminPermission = auth.user?.permissions.includes(ApplicationPermissions.LitigationAdmin);
    const hasRequiredPermission = props.roles.some((p) => auth.user?.permissions.includes(p));

    if (hasLitigationAdminPermission || hasRequiredPermission) {
        return <>{props.children}</>;
    }

    return <AccessDenied />;
};

export default PermissionRoute;
